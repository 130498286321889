import axios from 'axios'
import { SPECIALTIES_URL } from 'constants/api'
import * as Types from 'constants/types'

export const getSpecialties = () => (dispatch) => {
    dispatch(getSpecialtiesStart())

    axios.post(SPECIALTIES_URL, {
        oms_number: 7789899726001305, // TODO
        date_birth: '2001-10-23',
    })
        .then((response) => dispatch(getSpecialtiesSuccess(response.data.result)))
        .catch((err) => {
            console.error(err)
            dispatch(getSpecialtiesFail())
        })
}

const getSpecialtiesStart = () => {
    return {
        type: Types.FETCH_SPECIALTIES_START,
    }
}

const getSpecialtiesSuccess = (specialties) => {
    return {
        type: Types.FETCH_SPECIALTIES_SUCCESS,
        payload: specialties,
    }
}

const getSpecialtiesFail = () => {
    return {
        type: Types.FETCH_SPECIALTIES_FAIL,
    }
}
