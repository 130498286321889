import React from 'react'
import { Typography } from '@mui/material'
import { ListHospitals } from 'components'
import styles from './Appointment.module.scss'

const Appointment = () => {
    return (
        <section className={styles.container}>
            <Typography component="h3" variant="h3">
                Записаться к терапевту
            </Typography>

            <ListHospitals />
        </section>
    )
}

export default Appointment
