import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import {
    Button,
    Checkbox,
    FormControlLabel,
    InputBase,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from '@mui/material'
import { plural } from 'utils/strings'
import styles from './AnyAppointment.module.scss'

const AnyAppointment = ({ doctors }) => {
    const [timeStart, setTimeStart] = useState(null)
    const [timeEnd, setTimeEnd] = useState(null)
    const [selectedValue, setSelectedValue] = useState('any')
    const [selectedDoctors, setSelectedDoctors] = useState([])

    const onChangeRadio = (event) => {
        setSelectedValue(event.target.value)
    }

    const onChangeSelectedDoctors = (event) => {
        const { value } = event.target
        setSelectedDoctors(typeof value === 'string' ? value.split(',') : value)
    }

    const getPluralString = (selected) => {
        const countOfSelected = plural(selected.length, ['врач', 'врача', 'врачей'])
        const wordOfSelected = selected.length > 1 ? 'Выбраны' : 'Выбран'
        return `${wordOfSelected} ${selected.length} ${countOfSelected}`
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>Отложенная запись к терапевту</div>

            <RadioGroup className={styles.radioGroup} value={selectedValue} onChange={onChangeRadio}>
                <FormControlLabel value="any" control={<Radio />} label="Записаться к любому врачу" />
                <FormControlLabel value="certain" control={<Radio />} label="Записаться к определенным врачам" />
            </RadioGroup>

            <div className={styles.content}>
                {selectedValue === 'certain' && (
                    <div>
                        <label>Выберите врачей</label>
                        <Select
                            className={styles.select}
                            value={selectedDoctors}
                            onChange={onChangeSelectedDoctors}
                            input={<InputBase />}
                            renderValue={getPluralString}
                            multiple
                        >
                            {doctors.map(({ fullName }) => (
                                <MenuItem key={fullName} value={fullName}>
                                    <Checkbox checked={selectedDoctors.indexOf(fullName) > -1} />
                                    <ListItemText primary={fullName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                )}

                <div>
                    <label>Выберите промежуток времени</label>

                    <div className={styles.timePickers}>
                        <DatePicker
                            id="timepicker_start"
                            selected={timeStart}
                            onChange={setTimeStart}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Начало"
                            dateFormat="HH:mm"
                            timeIntervals={60}
                            customInput={<InputBase />}
                        />
                        <DatePicker
                            id="timepicker_end"
                            selected={timeEnd}
                            onChange={setTimeEnd}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Конец"
                            dateFormat="HH:mm"
                            timeIntervals={60}
                            customInput={<InputBase />}
                        />
                    </div>
                </div>
            </div>

            <Button className={styles.button} color="darker">
                Записаться
            </Button>
        </div>
    )
}

AnyAppointment.propTypes = {
    doctors: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        fullName: PropTypes.string,
        specialityName: PropTypes.string,
        specialityId: PropTypes.number,
    })),
}

export default AnyAppointment
