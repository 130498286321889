import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { CssBaseline, ThemeProvider } from '@mui/material'
import ru from 'date-fns/locale/ru'
import store from 'store'
import { theme } from './theme'

import App from './App'

registerLocale('ru', ru)
setDefaultLocale('ru')

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Provider store={store}>
                    <App />
                </Provider>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)
