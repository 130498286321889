import { createSelector } from 'reselect'

const getIsLoading = (state) => state.specialties.isLoading
const getIsError = (state) => state.specialties.isError
const getSpecialties = (state) => state.specialties.specialties

export const getSpecialtiesState = createSelector(
    getIsLoading,
    getIsError,
    getSpecialties,
    (isLoading, isError, specialties) => ({ isLoading, isError, specialties }),
)
