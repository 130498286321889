export class ModelUtils {
    static convertHospitals(model) {
        const hospitals = model.result.reduce((acc, currVal) => {
            if (!currVal.complexResource || !currVal.complexResource.length) return acc

            // Поиск поликлиники с таким же lpuId
            const hospitalIndex = acc.findIndex((hospital) => {
                return hospital.id === currVal.complexResource[0].room.lpuId
            })

            // Если такой поликлиники нет, то добавляем в массив новый элемент
            if (hospitalIndex === -1) {
                const complex = currVal.complexResource[0].room
                const doctor = ModelUtils.getDoctor(currVal.mainDoctor)

                const hospital = {
                    id: complex.lpuId,
                    name: complex.lpuShortName.trim(),
                    address: complex.defaultAddress,
                    availabilityDate: complex.availabilityDate,
                    doctors: [doctor],
                }

                acc.push(hospital)
            } else { // Если такая поликлиника присутствует, то обновляем список врачей
                const doctor = ModelUtils.getDoctor(currVal.mainDoctor)
                acc[hospitalIndex].doctors.push(doctor)
            }

            return acc
        }, [])

        console.log(hospitals)

        return hospitals
    }

    static getDoctor(doctor) {
        return {
            id: doctor.employeeId,
            fullName: ModelUtils.getFullName(doctor.firstName, doctor.lastName, doctor.secondName),
            specialityName: doctor.specialityName,
            specialityId: doctor.specialityId,
        }
    }

    static getFullName(firstName, lastName, secondName) {
        return [firstName, lastName, secondName].join(' ')
    }
}
