import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Typography } from '@mui/material'
import { getSpecialties } from 'actions/SpecialitiesActions'
import { getSpecialtiesState } from 'selectors/SpecialtiesSelectors'
import { Loader } from 'components'
import Speciality from './Speciality'
import styles from './ListSpecialties.module.scss'

const ListSpecialties = () => {
    const { isLoading, isError, specialties } = useSelector(getSpecialtiesState)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!specialties) {
            dispatch(getSpecialties())
        }
    }, [specialties])

    if (isError) {
        return (
            <section className={clsx(styles.container, styles.loading)}>
                <Alert severity="error">
                    <Typography>Ошибка. Похоже что-то пошло не так.</Typography>
                </Alert>
            </section>
        )
    }

    return (
        <section className={clsx(styles.container, isLoading && styles.loading)}>
            {isLoading ? <Loader size="medium" /> : (
                <>
                    {specialties.map((specialist) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Speciality key={specialist.code} name={specialist.name} code={specialist.code} />
                    ))}
                </>
            )}
        </section>
    )
}

export default ListSpecialties
