import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import styles from './Speciality.module.scss'

const Speciality = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const onAppointmentClick = () => {
        navigate('appointment')
    }

    return (
        <div>
            <div className={styles.header}>
                <Typography component="h3" variant="h3">
                    Специальность - терапевт
                </Typography>
                <Button color="darkest" onClick={onAppointmentClick}>
                    Записаться к терапевту
                </Button>
            </div>

            <section className={styles.content}>
                <div>
                    Терапевт – это врач, занимающийся диагностикой, лечением и профилактикой широкого круга заболеваний
                    внутренних органов. Также консультация терапевта необходима перед предстоящей операцией, при
                    беременности, планируемой записи в спортивные и оздоровительные клубы и секции, а также при
                    хронических заболеваниях.
                </div>

                <div className={styles.additionalContent}>
                    <div>
                        <div className={styles.title}>Что лечит терапевт?</div>

                        <ul>
                            <li>
                                Простудные и респираторные заболевания, в том числе ОРВИ, грипп, бронхит, трахеит и пневмонию.
                            </li>
                            <li>
                                Простудные и респираторные заболевания, в том числе ОРВИ, грипп, бронхит, трахеит и пневмонию.
                            </li>
                            <li>
                                Простудные и респираторные заболевания, в том числе ОРВИ, грипп, бронхит, трахеит и пневмонию.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className={styles.title}>Когда стоит обратиться?</div>

                        <ul>
                            <li>
                                Простудные и респираторные заболевания, в том числе ОРВИ, грипп, бронхит, трахеит и пневмонию.
                            </li>
                            <li>
                                Простудные и респираторные заболевания, в том числе ОРВИ, грипп, бронхит, трахеит и пневмонию.
                            </li>
                            <li>
                                Простудные и респираторные заболевания, в том числе ОРВИ, грипп, бронхит, трахеит и пневмонию.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Speciality
