import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from './Speciality.module.scss'

const Speciality = ({ name, code }) => {
    return (
        <div>
            <Link to={`/specialties/${code}/appointment`} className={styles.container}>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 15.5C15.5 17.9165 13.5415 19.875 11.125 19.875C8.70854 19.875 6.75 17.9165 6.75 15.5H15.5ZM25.7083 15.5C24.5481 15.5 23.4352 15.961 22.6148 16.7814C21.7943 17.6019 21.3333 18.7148 21.3333 19.875C21.3333 21.0353 21.7943 22.1481 22.6148 22.9686C23.4352 23.789 24.5481 24.25 25.7083 24.25C26.8686 24.25 27.9814 23.789 28.8019 22.9686C29.6224 22.1481 30.0833 21.0353 30.0833 19.875C30.0833 18.7148 29.6224 17.6019 28.8019 16.7814C27.9814 15.961 26.8686 15.5 25.7083 15.5Z" fill="black"/>
                    <path d="M24.2501 22.7917V24.25C24.2501 25.8615 22.9449 27.1667 21.3334 27.1667H15.5001C13.8886 27.1667 12.5834 25.8615 12.5834 24.25V18.3C17.5228 17.5883 21.3334 13.3373 21.3334 8.17333V3.83333C21.3334 2.22187 20.0282 0.916664 18.4167 0.916664H16.9584C16.1534 0.916664 15.5001 1.57 15.5001 2.375C15.5001 3.18 16.1534 3.83333 16.9584 3.83333H18.4167V7.88458C18.4167 11.6894 15.643 15.0946 11.8557 15.4635C7.50842 15.8894 3.83341 12.471 3.83487 8.17479L3.83341 3.83333H5.29175C6.09675 3.83333 6.75008 3.18 6.75008 2.375C6.75008 1.57 6.09675 0.916664 5.29175 0.916664H3.83341C3.05466 0.916664 2.32112 1.22 1.77133 1.77125C1.22008 2.3225 0.916748 3.05604 0.916748 3.83333V8.20833C0.916748 13.3402 4.72737 17.5883 9.66675 18.3V24.25C9.66675 27.4715 12.2786 30.0833 15.5001 30.0833H21.3334C24.5549 30.0833 27.1667 27.4715 27.1667 24.25V22.7917H24.2501Z" fill="black"/>
                </svg>

                <span>{name}</span>
            </Link>
        </div>
    )
}

Speciality.propTypes = {
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
}

export default Speciality
