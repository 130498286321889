import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Button, Box, Container, Grid, Toolbar, Typography } from '@mui/material'
import styles from './Header.module.scss'

const Header = () => {
    return (
        <>
            <AppBar elevation={0} position="static" color="transparent" className={styles.header}>
                <Toolbar>
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid md="3" item>
                                Logo
                            </Grid>
                            <Grid md="6" item />
                            <Grid md="3" item>
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end">
                                    <Button color="lighter">
                                        Личный кабинет
                                    </Button>
                                    <Button color="darker">
                                        Записаться к врачу
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
            <Box component="ul" display="flex" justifyContent="center" alignItems="center" className={styles.headerBottom}>
                <Typography variant="subtitle1" component="li">
                    <Link to="/specialties">
                        Специальности
                    </Link>
                </Typography>
                <Typography variant="subtitle1" component="li">
                    <Link to="/">
                        Справки
                    </Link>
                </Typography>
                <Typography variant="subtitle1" component="li">
                    <Link to="/">
                        Клиники
                    </Link>
                </Typography>
            </Box>
        </>
    )
}

export default Header
