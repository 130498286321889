import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getHospitalState } from 'selectors/AppointmentSelectors'
import { getHospitals } from 'actions/AppointmentActions'
import { Loader } from 'components'
import clsx from 'clsx'
import { Alert, Typography } from '@mui/material'
import Hospital from './Hospital'
import styles from './ListHospitals.module.scss'

const ListHospitals = () => {
    const { isLoading, isError, hospitals } = useSelector(getHospitalState)
    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getHospitals(id))
    }, [])

    if (isError) {
        return (
            <section className={styles.container}>
                <Alert severity="error">
                    <Typography>Ошибка. Похоже что-то пошло не так.</Typography>
                </Alert>
            </section>
        )
    }

    return (
        <div className={styles.container}>
            {isLoading ? <Loader size="medium" /> : (
                <>
                    {hospitals.map((hospital) => (
                        <Hospital key={hospital.id} hospital={hospital} />
                    ))}
                </>
            )}
        </div>
    )
}

export default ListHospitals
