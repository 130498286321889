import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    typography: {
        fontFamily: 'Raleway, sans-serif',
        subtitle1: {
            fontSize: '1.17rem',
            fontWeight: 'bold',
            letterSpacing: '0.085em',
            textTransform: 'uppercase',
        },
        h3: {
            fontSize: '1.58rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '.1rem',
            marginBottom: '30px',
        },
    },
    palette: {
        lighter: {
            main: '#02C39A',
        },
        primary: {
            main: '#00A896',
        },
        darker: {
            main: '#028090',
        },
        darkest: {
            main: '#05668D',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',
                    textTransform: 'none',
                    borderRadius: '9px',
                    fontSize: '1.04rem',
                },
            },
            defaultProps: {
                variant: 'contained',
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    background: '#FCFCFC',
                    borderRadius: '7px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.19)',
                    border: '1px solid rgba(2, 128, 144, 0.62)',
                    padding: '8px 13px',
                    height: 'auto',
                    fontFamily: 'sans-serif',
                    fontSize: '1.05rem',
                    '&:focus': {
                        border: '1px solid #028090',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingRight: '0px!important',
                },
                icon: {
                    right: '0px!important',
                },
            },
        },
    },
})
