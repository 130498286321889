import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './Loader.module.scss'

const Loader = ({ size }) => {
    return (
        <div className={clsx(styles.container, styles[size])}>
            <div className={clsx(styles.element, styles[size])}>
                <div />
            </div>
        </div>
    )
}

Loader.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'big']),
}

Loader.defaultProps = {
    size: 'big',
}

export default Loader
