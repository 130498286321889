import { createSelector } from 'reselect'

const getIsLoading = (state) => state.appointment.isLoading
const getIsError = (state) => state.appointment.isError
const getHospitals = (state) => state.appointment.hospitals

export const getHospitalState = createSelector(
    getIsLoading,
    getIsError,
    getHospitals,
    (isLoading, isError, hospitals) => ({ isLoading, isError, hospitals }),
)
