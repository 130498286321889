/**
 * Множественное число
 * @param count {number|string}
 * @param forms {string[]}
 * @return {string}
 */
export const plural = (count, forms) => {
    if (count % 10 === 1 && count % 100 !== 11) {
        return forms[0]
    }

    if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
        return forms[1]
    }

    return forms[2]
}
