import * as Types from 'constants/types'

const initialState = {
    hospitals: null,
    isLoading: true,
    isError: false,
}

const AppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_HOSPITALS_START:
            return {
                ...state,
                isLoading: true,
            }
        case Types.FETCH_HOSPITALS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                hospitals: action.payload,
            }
        case Types.FETCH_HOSPITALS_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
                hospitals: null,
            }
        default:
            return state
    }
}

export default AppointmentReducer
