import * as Types from 'constants/types'

const initialState = {
    specialties: null,
    isLoading: true,
    isError: false,
}

const SpecialtiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_SPECIALTIES_START:
            return {
                ...state,
                isLoading: true,
            }
        case Types.FETCH_SPECIALTIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                specialties: action.payload,
            }
        case Types.FETCH_SPECIALTIES_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
                specialties: null,
            }
        default:
            return state
    }
}

export default SpecialtiesReducer
