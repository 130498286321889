import React from 'react'
import { Paper } from '@mui/material'
import styles from './Home.module.scss'

const Home = () => {
    return (
        <Paper elevation={0}>
            Home
        </Paper>
    )
}

export default Home
