import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTheme } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import AnyAppointment from '../AnyAppointment'
import styles from './Hospital.module.scss'

const Hospital = ({ hospital }) => {
    const [appointmentMode, setAppointmentMode] = useState(false)
    const theme = useTheme()

    const onClickAppointmentMode = useCallback(() => {
        setAppointmentMode((prevMode) => !prevMode)
    }, [])

    const arrowClassName = clsx(styles.arrowIcon, appointmentMode && styles.arrowIcon_open)

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div>
                    <div className={styles.title}>{hospital.name}</div>
                    <div className={styles.address}>
                        <span>{hospital.address}</span>
                        <span style={{ color: theme.palette.darkest.main }}>показать на карте</span>
                    </div>
                </div>
                <div>
                    <ArrowBackIos onClick={onClickAppointmentMode} fontSize="large" className={arrowClassName} />
                </div>
            </div>

            {appointmentMode && <AnyAppointment doctors={hospital.doctors} />}
        </div>
    )
}

Hospital.propTypes = {
    hospital: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        address: PropTypes.string,
        availabilityDate: PropTypes.string,
        doctors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            fullName: PropTypes.string,
            specialityName: PropTypes.string,
            specialityId: PropTypes.number,
        })),
    }).isRequired,
}

export default Hospital
