import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Layout } from 'components'
import { Home, Specialties, Speciality, Appointment } from 'pages'
import './styles/index.scss'

const App = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/specialties" element={<Specialties />} />
                <Route path="/specialties/:id" element={<Speciality />} exact />
                <Route path="/specialties/:id/appointment" element={<Appointment />} />
            </Routes>
        </Layout>
    )
}

export default App
