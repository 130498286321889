import React from 'react'
import { Paper, Typography } from '@mui/material'
import { ListSpecialties } from 'components'

const Specialties = () => {
    return (
        <Paper elevation={0}>
            <Typography component="h3" variant="h3">
                Специальности
            </Typography>

            <ListSpecialties />
        </Paper>
    )
}

export default Specialties
