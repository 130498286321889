import React from 'react'
import PropTypes from 'prop-types'
import { Container, Grid } from '@mui/material'
import Header from '../Header'
import Footer from '../Footer'
import styles from './Layout.module.scss'

const Layout = ({ children }) => {
    return (
        <>
            <Header />
            <Container maxWidth="lg" className={styles.children}>
                <Grid container spacing={3}>
                    <Grid md={12} item>
                        {children}
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

Layout.defaultProps = {
    children: null,
}

export default Layout
