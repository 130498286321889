import axios from 'axios'
import { DOCTORS_URL } from 'constants/api'
import { ModelUtils } from 'utils/model'
import * as Types from 'constants/types'

export const getHospitals = (specialityId) => (dispatch) => {
    dispatch(getHospitalsStart())

    axios.post(DOCTORS_URL, {
        oms_number: 7789899726001305, // TODO
        date_birth: '2001-10-23',
        speciality_id: specialityId,
    })
        .then((resp) => ModelUtils.convertHospitals(resp.data))
        .then((hospitals) => dispatch(getHospitalsSuccess(hospitals)))
        .catch((err) => {
            console.error(err)
            dispatch(getHospitalsFail())
        })
}

const getHospitalsStart = () => {
    return {
        type: Types.FETCH_HOSPITALS_START,
    }
}

const getHospitalsSuccess = (specialties) => {
    return {
        type: Types.FETCH_HOSPITALS_SUCCESS,
        payload: specialties,
    }
}

const getHospitalsFail = () => {
    return {
        type: Types.FETCH_HOSPITALS_FAIL,
    }
}
